<template>
  <v-container
    fluid
    fill-height
    pa-2
    class="conteneur-page gradient-bleu align-start heritage-scroll"
  >
    <timeline-pointages type="groupe"></timeline-pointages>
  </v-container>
</template>
<script>
import TimelinePointages from "@/views/TimelinePointages";
export default {
  components: {
    TimelinePointages,
    //
  },
  data: () => ({
    //
  }),
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
<style scoped>
</style>
